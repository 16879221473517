import { eipsType } from './../types'

export const EIPs: eipsType = {
  1559: require('./1559.json'),
  2315: require('./2315.json'),
  2537: require('./2537.json'),
  2565: require('./2565.json'),
  2718: require('./2718.json'),
  2929: require('./2929.json'),
  2930: require('./2930.json'),
  3198: require('./3198.json'),
  3529: require('./3529.json'),
  3541: require('./3541.json'),
  3554: require('./3554.json'),
}
